import React from 'react';
import Hero from './home-components/hero';
import Features from './home-components/features';
import About from './home-components/about';
import AboutIntro from './home-components/about-intro';
import HowWorks from './home-components/how-works';
import SpecialFeatures from './home-components/special-features';
import Pricing from './home-components/pricing';
import VideoPromo from './home-components/video-promo';
//import Testimonial from './home-components/testimonial';
import Subscribe from './home-components/subscription';
import FactsSection from './home-components/facts-section';
//import Blog from './home-components/blog';
//import Contact from './home-components/contact';

const Home = () => {
    return <div>
        <Hero />
        <Features />
        <About />
        <AboutIntro />
        <HowWorks />
        <SpecialFeatures />
        <Pricing />
        <FactsSection />
        <Subscribe />
        <VideoPromo />
    </div>
}


export default Home
